import DatePicker from "react-datepicker";
import { DATEPICKER_PLACEHOLDER } from "../../../Constants/stringConstants";
import * as storageConstants from "../../../Constants/storageConstant";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import { Row, Col, Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

function DateRangePicker({ startDate, showDateShortcuts, setStartDate, endDate, setEndDate, datePickerStartRef, datePickerEndRef, onChange, allowFutureDates = false }) {

  const handleStartDateChange = (date) => {
    setStartDate(date);
    datePickerStartRef?.current.setOpen(false);
    datePickerEndRef?.current.setOpen(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    datePickerEndRef?.current.setOpen(false);
  };

  const ShortcutType = {
    "Today": {
      key: "TODAY",
      displayValue: "Today",
    },
    "Yesterday": {
      key: "YESTERDAY",
      displayValue: "Yesterday",
    },
    "This Month": {
      key: "THIS_MONTH",
      displayValue: "This Month",
    },
    "Last Month": {
      key: "LAST_MONTH",
      displayValue: "Last Month",
    },
    "This Year": {
      key: "THIS_YEAR",
      displayValue: "This Year",
    },
    "Last Year": {
      key: "LAST_YEAR",
      displayValue: "Last Year",
    },
    Clear: {
      key: "CLEAR",
      displayValue: "Clear",
    },
  };

  const shortcutTypes = {};
  for (const key in ShortcutType) {
    if (ShortcutType.hasOwnProperty(key)) {
      shortcutTypes[ShortcutType[key].displayValue] = ShortcutType[key].key;
    }
  }

  const handleShortcutClick = (shortcut, e) => {
    const today = new Date();
    let start,
      end = null;
    e.preventDefault();

    switch (shortcut) {
      case ShortcutType["Today"].displayValue:
        start = today;
        end = today;
        break;
      case ShortcutType["Yesterday"].displayValue:
        start = new Date(today);
        start.setDate(today.getDate() - 1);
        end = new Date(today);
        end.setDate(today.getDate() - 1);
        break;
      case ShortcutType["This Month"].displayValue:
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case ShortcutType["Last Month"].displayValue:
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case ShortcutType["This Year"].displayValue:
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case ShortcutType["Last Year"].displayValue:
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case ShortcutType["Clear"].displayValue:
        start = null;
        end = null;
        break;
      default:
        return;
    }

    onChange(start, end);
  };

  return (
    <div>
      <Row className="date-picker mx-0">
        <Col
          md={5}
          xs={showDateShortcuts ? 5 : 6}
          sm={5}
          className="px-0 me-2 me-sm-0"
        >
          <div className="form-input-group w-100">
            <div className="input-group flex-nowrap">
              <span className="input-group-text" id="basic-addon1">
                <IconResource
                  color="var(--primary)"
                  fontSize={"20px"}
                  type={startDate ? "cross" : "calendar"}
                  onClick={(startdate) => startdate ? handleStartDateChange(null) : handleStartDateChange(new Date())}
                />
              </span>
              <DatePicker
                ref={datePickerStartRef}
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={allowFutureDates ? null : new Date()}
                placeholderText={DATEPICKER_PLACEHOLDER}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                scrollableMonthYearDropdown
                showYearDropdown
                yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                scrollableYearDropdown
              />
            </div>
          </div>
        </Col>
        <Col
          md={showDateShortcuts ? 1 : 2}
          xs={0}
          sm={1}
          className="px-0 my-0 d-none d-sm-block"
        >
          <div
            style={{ minWidth: "25px" }}
            className="d-flex align-items-center justify-content-center h-100 "
          >
            <IconResource
              type="rightArrow"
              fontSize={40}
              color={"var(--primary)"}
            />
          </div>
        </Col>
        <Col md={5} xs={5} sm={5} className="px-0">
          <div className="form-input-group w-100">
            <div className="input-group flex-nowrap">
              <span className="input-group-text" id="basic-addon1">
                <IconResource
                  color="var(--primary)"
                  fontSize={"20px"}
                  type={endDate ? "cross" : "calendar"}
                  onClick={(endDate) => endDate ? handleEndDateChange(null) : handleEndDateChange(new Date())}
                />
              </span>
              <DatePicker
                ref={datePickerEndRef}
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                endDate={endDate}
                startDate={startDate}
                minDate={startDate}
                maxDate={allowFutureDates ? null : new Date()}
                placeholderText={DATEPICKER_PLACEHOLDER}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                scrollableMonthYearDropdown
                showYearDropdown
                yearDropdownItemNumber={storageConstants.YEARS_TOTAL}
                scrollableYearDropdown
              />
            </div>
          </div>
        </Col>
        {showDateShortcuts ? (
          <Col md={1} xs={1} className="pe-0 ps-0 ps-md-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <IconResource
                  type={"dropdown"}
                  size={20}
                  color="var(--primary)"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("Today", e)}
                >
                  Today
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("Yesterday", e)}
                >
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("This Month", e)}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("Last Month", e)}
                >
                  Last Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("This Year", e)}
                >
                  This Year
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleShortcutClick("Last Year", e)}
                >
                  Last Year
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleShortcutClick("Clear", e)}>
                  Clear
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default DateRangePicker;
